.react-calculator .result-panel {
    background: rgb(255, 255, 255);
  
}
.s1{
    padding: 0;

}
.calcContainer{
    height: 90%;
}
.rounded {
    border-radius: var(--bs-border-radius)!important;
    max-width: 400px;
}
